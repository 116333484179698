import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "../pages/Login";
import LayoutOutlet from "./LayoutOutlet";
import AllCodeSheet from "../pages/AllCodeSheet";
import DynamicReport from "../pages/DynamicReport";
import DailyVendorRequirement from "../pages/reports/DailyVendorRequirement";
import HardCopyOfBillReceivings from "../pages/reports/HardCopyOfBillReceivings";
import ItemMaster from "../pages/ItemMaster";
import VendorPortalBillUpdation from "../pages/reports/VendorPortalBillUpdation";
import DailyCashTransactions from "../pages/DailyCashTransactions";
import VehicleChallan from "../pages/reports/VehicleChallan";
import TotalStaffDetails from "../pages/reports/TotalStaffDetails";
import VehicleService from "../pages/reports/VehiceService";
import Donations from "../pages/reports/Donations";
import DailyTransactionHeadOffice from "../pages/DailyTransactionHeadOffice";
import HeadOfficeCashReceiving from "../pages/HeadOfficeCashReceiving";
import HeadOfficeCashPayment from "../pages/HeadOfficeCashPayment";
import AllBill from "../pages/AllBill";
import CashTrackOffice from "../pages/CashTrackOffice";
import SiteConsumption from "../pages/SiteConsumption";
import HomeExpenses from "../pages/reports/HomeExpenses";
import PurchaseVerify from "../pages/reports/PurchaseVerify";
import PurchaseDiscrepancy from "../pages/PurchaseDiscrepancy";
import PurchaseConslusion from "../pages/PurchaseConclusion";
import VendorCode from "../pages/VendorCode";
import HeadOfficeCashApproval from "../pages/HeadOfficeCashApproval";
import PaidBill from "../pages/PaidBill";
import ThankYou from "../pages/ThankYou";
import VendorQuotes from "../pages/VendorQuotes";
import BalanceToPay from "../pages/BalanceToPay";
import InStock from "../pages/InStock";
import ToBeRefunded from "../pages/ToBeRefunded";
import Consolidated from "../pages/Consolidated";
import VendorQuotesApproved from "../pages/VendorQuotesApproved";
import ProfileVendor from "../pages/ProfileVendor";
import ProfileContractorSupplier from "../pages/ProfileContractorSupplier";
import SiteDiscrepancy from "../pages/SiteDiscrepancy";
import SiteConclusion from "../pages/SiteConclusion";
import PurposeOfPayment from "../pages/PurposeOfPayment";
import Export from "../pages/Export";
import ItemBillAdjusted from "../pages/ItemBillAdjusted";
import Mismatched from "../pages/Mismatched";
import Dashboard from "../pages/Dashboard";
import Unauthorized from "../pages/Unauthorized";
import PurchaseSiteDiscrepancy from "../pages/PurchaseSiteDiscrepancy";
import PurchaseSiteConclusion from "../pages/PurchaseSiteConclusion";
import DailyCashRequirement from "../pages/DailyCashRequirement";
import DailyFuelPurchase from "../pages/reports/DailyFuelPurchase";

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <LayoutOutlet />,
      children: [
        {
          index: true,
          element: <Dashboard />,
          handle: { menu_key: "dashboard" },
        },
        {
          path: "daily_transaction_head_office",
          element: <DailyTransactionHeadOffice />,
          handle: { menu_key: "head_office_transaction" },
        },
        {
          path: "purpose_of_payment",
          element: <PurposeOfPayment />,
          handle: { menu_key: "purpose_payment" },
        },
        {
          path: "export",
          element: <Export />,
          handle: { menu_key: "exports" },
        },
        {
          path: "head_office_cash_receiving",
          element: <HeadOfficeCashReceiving />,
        },
        {
          path: "head_office_cash_payment",
          element: <HeadOfficeCashPayment />,
        },
        {
          path: "head_office_cash_approval",
          element: <HeadOfficeCashApproval />,
        },
        {
          path: "cash_track/cash_track_office",
          element: <CashTrackOffice />,
          handle: { menu_key: "cash_track" },
        },
        {
          path: "cash_track/daily_cash_transactions",
          element: <DailyCashTransactions />,
          handle: { menu_key: "user_transaction" },
        },
        {
          path: "cash_track/daily_cash_requirement",
          element: <DailyCashRequirement />,
          handle: { menu_key: "daily_cash_requirement" },
        },
        {
          path: "cash_track/mismatched",
          element: <Mismatched />,
          handle: { menu_key: "mismatched" },
        },
        {
          path: "profile/vendor",
          element: <ProfileVendor />,
          handle: { menu_key: "vendor" },
        },
        {
          path: "profile/office",
          element: <AllCodeSheet />,
          handle: { menu_key: "office" },
        },
        {
          path: "profile/contractor-supplier",
          element: <ProfileContractorSupplier />,
          handle: { menu_key: "contractor_subpplier" },
        },
        {
          path: "home_expenses/site_consumption",
          element: <SiteConsumption />,
        },
        {
          path: "home_expenses/item_master",
          element: <ItemMaster />,
          handle: { menu_key: "site_item_master" },
        },
        {
          path: "home_expenses/item_bills_adjusted",
          element: <ItemBillAdjusted />,
        },
        {
          path: "bills/all_bill",
          element: <AllBill />,
          handle: { menu_key: "all_bills" },
        },
        {
          path: "bills/paid_bill",
          element: <PaidBill />,
          handle: { menu_key: "paid_bills" },
        },
        {
          path: "report/daily_vendor_requirement",
          element: <DailyVendorRequirement />,
          handle: { menu_key: "daily_vendor_requirement" },
        },
        {
          path: "report/daily_fuel_purchase",
          element: <DailyFuelPurchase />,
          handle: { menu_key: "daily_fuel_purchase" },
        },
        {
          path: "report/hard_copy_of_bill_receivings",
          element: <HardCopyOfBillReceivings />,
          handle: { menu_key: "hard_copy_of_bill_receivings" },
        },
        {
          path: "report/vendor_portal_bill_updation",
          element: <VendorPortalBillUpdation />,
        },
        {
          path: "report/daily_cash_transactions",
          element: <DailyCashTransactions />,
        },
        {
          path: "report/vehicle_challan",
          element: <VehicleChallan />,
          handle: { menu_key: "vehicle_challan" },
        },
        {
          path: "report/total_staff_details",
          element: <TotalStaffDetails />,
          handle: { menu_key: "total_staff_details" },
        },
        {
          path: "report/vehicle_service",
          element: <VehicleService />,
          handle: { menu_key: "vehicle_service" },
        },
        {
          path: "report/donations",
          element: <Donations />,
          handle: { menu_key: "donations" },
        },
        {
          path: "report/home_expense",
          element: <HomeExpenses />,
        },
        {
          path: "report/purchase_verify",
          element: <PurchaseVerify />,
        },
        {
          path: "discrepancy/purchase",
          element: <PurchaseDiscrepancy />,
          handle: { menu_key: "home_expences_mismatch" },
        },
        {
          path: "conclusion/purchase",
          element: <PurchaseConslusion />,
          handle: { menu_key: "home_expences_conclusion" },
        },
        {
          path: "discrepancy/site",
          element: <SiteDiscrepancy />,
          handle: { menu_key: "site_mismatch" },
        },
        {
          path: "mismatch/site_home_expenses",
          element: <PurchaseSiteDiscrepancy />,
          handle: { menu_key: "mismatch" },
        },
        {
          path: "conclusion/site_home_expenses",
          element: <PurchaseSiteConclusion />,
          handle: { menu_key: "conclusion" },
        },
        {
          path: "conclusion/site",
          element: <SiteConclusion />,
          handle: { menu_key: "site_conclusion" },
        },
        {
          path: "contractor_supplier_rate_approved/quote_to_approve",
          element: <VendorQuotes />,
          handle: { menu_key: "quote_approve" },
        },
        {
          path: "contractor_supplier_rate_approved/approved_quote",
          element: <VendorQuotesApproved />,
          handle: { menu_key: "approved_quote" },
        },
        {
          path: "engineering-sheet/consolidated",
          element: <Consolidated />,
        },
        {
          path: "engineering-sheet/balance-to-pay",
          element: <BalanceToPay />,
          handle: { menu_key: "balance_to_pay" },
        },
        {
          path: "engineering-sheet/in-stock",
          element: <InStock />,
          handle: { menu_key: "in_stock" },
        },
        {
          path: "engineering-sheet/to-be-refunded",
          element: <ToBeRefunded />,
          handle: { menu_key: "to_be_refunded" },
        },
      ],
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/unauthorized",
      element: <Unauthorized />,
    },
    {
      path: "/vendor/:code/:emp",
      element: <VendorCode />,
    },
    {
      path: "/vendor-success",
      element: <ThankYou />,
    },
  ],
  {
    basename: process.env.REACT_APP_ENV === "dev" ? "/testhim" : "/",
  }
);

function Routing() {
  return <RouterProvider router={router} />;
}

export default Routing;
