import { useEffect, useRef, useState } from "react";
import Loader from "../components/Loader";
import moment from "moment";
import Select from "react-select";
import { DatePicker } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import InvoiceViewModal from "../components/InvoiceViewModal";
const { RangePicker } = DatePicker;

function Dashboard() {
  const navigate = useNavigate();

  // dashboard
  const [checkBoxesState, setcheckBoxesState] = useState({
    transaction: false,
    liabilities: false,
    home: false,
    site: false
  });

  const checkSelected = () => {
    if (checkBoxesState.transaction || checkBoxesState.liabilities || checkBoxesState.home || checkBoxesState.site) {
      return true;
    } else {
      return false;
    }
  }

  const handleChange = (e) => {
    if (e.target.checked) {
      setcheckBoxesState({
        ...checkBoxesState,
        [e.target.id]: true,
      });
    } else {
      setcheckBoxesState({
        ...checkBoxesState,
        [e.target.id]: false,
      });
    }
  };
  // 
  const componentRef = useRef();
  //   const [show, setShow] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);


  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [showInvoice, setShowInvoice] = useState(false);
  const [totalCount, setTotalCount] = useState(true);
  const [otherData, setOtherData] = useState({});
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState();

  function handleshowInvoice(data) {
    setShowInvoice(data);
  }
  const [siteList, setSiteList] = useState([]);
  let columns = [
    {
      name: "Date",
      code: "date",
      format: (value) => moment(value).format("DD MMM YYYY"),
    },

    // {
    //   name: "Vendor Name",
    //   code: "vendor",
    //   format: (value) => (value != null ? value["name"] : ""),
    // },
    // {
    //   name: "Vendor Code",
    //   code: "vendor",
    //   format: (value) => (value != null ? value["code"] : ""),
    // },
    {
      name: "Invoice No",
      code: "purchase",
      format: (value) => {
        if (value != null) {
          if (value["inv_file"]) {
            return (
              <span>
                {value["inv_no"]}

                <button
                  onClick={() =>
                    handleshowInvoice({
                      inv_file: value["inv_file"],
                      inv_no: value["inv_no"],
                    })
                  }
                  type="button"
                  className="button "
                >
                  <svg className="icon fs-22">
                    <use href="#icon_eye"></use>
                  </svg>
                </button>
              </span>
            );
          } else {
            return value["inv_no"];
          }
        } else {
          return "";
        }
      },
    },
    {
      name: "Purchase By",
      code: "user",
      format: (value) => (value != null ? value["code"] : ""),
    },
    {
      name: "Purchase Item",
      code: "item_name",
      // format: (value) => (value != null ? value["item_name"] : ""),
    },
    {
      name: "Purchase Qty",
      code: "purchase_qty",
    },
    {
      name: "Rate",
      code: "amount_per_unit",
    },
    {
      name: "Total Amount",
      code: "purchase",
      format: (value) => {
        if (value != null) {
          return value["net_amount"];
        } else {
          return "";
        }
      },
    },
    {
      name: "Verified Qty",
      code: "verify_qty",
    },
    {
      name: "Discrepancy Qty",
      code: "discrepancy_qty",
    },
    // {
    //   name: "User Name",
    //   code: "employee_name",
    // },
    // {
    //   name: "User Code",
    //   code: "employee_code",
    // },
  ];
  let columns2 = [
    {
      name: "Date",
      code: "date",
      format: (value) => (value ? moment(value).format("DD MMM YYYY") : ""),
    },
    {
      name: "From Code",
      code: "from_code",
    },
    {
      name: "To Code",
      code: "to_code",
    },
    // {
    //   name: "Payment Type",
    //   code: "payment_type",
    //   format: (value) => {
    //     switch (value) {
    //       case 1:
    //         return "Payment";
    //       case 2:
    //         return "Receipt";
    //       default:
    //         return value;
    //     }
    //   },
    // },
    // {
    //   name: "Amount",
    //   code: "amount",
    // },
    {
      name: "Amount Paid",
      code: "debit_amount",
      // format: (value) => (value && value > 0 ? value : ""),
    },
    {
      name: "Amount Received",
      code: "credit_amount",
      // format: (value) => (value && value > 0 ? value : ""),
    },
    {
      name: "Balance In Hand",
      code: "wallet_bal",
      // format: (value) => (value && value > 0 ? value : ""),
      // format: (value) => "",
    },
    {
      // name: "Ledger Head",
      name: "Purpose Of Payment",
      code: "ledger_head_code",
      format: (value) => (value ? value : ""),
    },
    {
      name: "Site",
      code: "contact",
      format: (value) =>
        value?.code
          ? value?.code
          : //  + "-" + value?.name
          "",
    },
    {
      name: "Mismatched",
      code: "mismatched",
      // format: (value) => (value && value > 0 ? value : ""),
    },
  ];

  let columnsSite = [
    {
      name: "Date",
      code: "verify_date",
      format: (value) => (value ? moment(value).format("DD MMM YYYY") : ""),
    },

    {
      name: "Vendor Code",
      code: "vendor_code",
      format: (value) => value || "",
    },
    // {
    //   name: "Invoice No",
    //   code: "purchase",
    //   format: (value) => {
    //     if (value != null && value["inv_no"]) {
    //       if (value["inv_file"]) {
    //         return (
    //           <span>
    //             {value["inv_no"]}

    //             <button
    //               onClick={() =>
    //                 handleshowInvoice({
    //                   inv_file: value["inv_file"],
    //                   inv_no: value["inv_no"],
    //                 })
    //               }
    //               type="button"
    //               className="button "
    //             >
    //               <svg className="icon fs-22">
    //                 <use href="#icon_eye"></use>
    //               </svg>
    //             </button>
    //           </span>
    //         );
    //       } else {
    //         return value["inv_no"];
    //       }
    //     } else {
    //       return "";
    //     }
    //   },
    // },
    {
      name: "Verified By",
      code: "verify_by_user_code",
      format: (value) => value || "",
    },
    {
      name: "Invoice No",
      code: "inv_no",
      format: (value) => value || "",
    },
    {
      name: "Purchase Item",
      code: "item_name",
      format: (value) => value || "",
    },
    {
      name: "Purchase Qty",
      code: "qty",
    },
    {
      name: "Rate",
      code: "qty_price",
    },
    {
      name: "Total Amount",
      code: "actual_purchase_qty",
      format: (value, value1) => {
        if (value && value1) {
          return (parseFloat(value) * parseFloat(value1)).toFixed(2);
        } else {
          return "";
        }
      },
    },
    {
      name: "Verified Qty",
      code: "verify_qty",
    },
    {
      name: "Discrepancy Qty",
      code: "discrepancy_qty",
    },
    // {
    //   name: "User Name",
    //   code: "employee_name",
    // },
    // {
    //   name: "User Code",
    //   code: "employee_code",
    // },
  ];
  const [rows, setRows] = useState([]);
  const [isGettingRows, setIsGettingRows] = useState(false);

  const [rowsMismatch, setRowsMismatch] = useState([]);
  const [isGettingRowsMismatch, setIsGettingRowsMismatch] = useState(false);


  async function getRows() {
    try {
      // setIsGettingRows(true);
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/purchasediscrepancy`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: {
          "user_code": null,
          "site_code": null,
          // "date": "",
          from_date: selectedFromDate
            ? selectedFromDate?.format("YYYY-MM-DD")
            : null,
          to_date: selectedToDate ? selectedToDate?.format("YYYY-MM-DD") : null,
          "action_type": 1,
          // "paginate": false
        },
      });

      setRows(response?.data?.data || []);
      // setTotalCount(response?.data?.count);
    } catch (error) {
      // setShowErrModal(true);
      // setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsGettingRows(false);
    }
  }

  async function getRowsMismatch(
  ) {
    try {
      setIsGettingRowsMismatch(true);
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/getdailytransactionweb`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: {
          "site_code": null,
          "to_code": null,
          from_date: selectedFromDate
            ? selectedFromDate?.format("YYYY-MM-DD")
            : null,
          to_date: selectedToDate
            ? selectedToDate?.format("YYYY-MM-DD")
            : null,
          "ledger_head": null,
          // "page": 0,
          // "limit": 40,
          // "paginate": true
        },
      });

      let data = response?.data?.data || [];
      //data should be and array of objects which will be filterd out based on date and to_code means if array contains any object with same date and to_code then the credit_amount and debit_amount should be added and only one object should be there with the sum of credit_amount and debit_amount
      let filteredData = [];
      data.forEach((item) => {
        let index = filteredData.findIndex(
          (x) => x.date === item.date && x.to_code === item.to_code
        );
        if (index === -1) {
          filteredData.push({
            ...item,
            balance_in_hand:
              parseFloat(item.debit_amount) - parseFloat(item.credit_amount),
            mismatched:
              parseFloat(item.debit_amount) - parseFloat(item.credit_amount),
          });
        } else {
          filteredData[index].credit_amount =
            parseFloat(filteredData[index].credit_amount) +
            parseFloat(item.credit_amount);
          filteredData[index].debit_amount =
            parseFloat(filteredData[index].debit_amount) +
            parseFloat(item.debit_amount);
          filteredData[index].balance_in_hand =
            parseFloat(filteredData[index].debit_amount) -
            parseFloat(filteredData[index].credit_amount);
          filteredData[index].mismatched =
            parseFloat(filteredData[index].debit_amount) -
            parseFloat(filteredData[index].credit_amount);
        }
      });
      data = filteredData?.filter((item) => item?.mismatched != 0);
      setRowsMismatch(data);
      setTotalCount(response?.data?.count);
      setOtherData({
        total_amount_received: response?.data?.total_received,
        total_amount_paid: response?.data?.total_paid,
        wallet_balance: response?.data?.wallet_bal,
      });
      // setTotalCount(response?.data?.count);
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsGettingRowsMismatch(false);
    }
  }

  const [rowsLiablities, setRowsLiablities] = useState([]);
  const [isGettingRowsLiablities, setIsGettingRowsLiablities] = useState(false);

  async function getRowsLiablities(
  ) {
    try {
      setIsGettingRowsLiablities(true);
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/getutilitybill`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: {
          "site_code": "",
          "search_input": "",
          "service": "",
          "type": "",
          // from_date: selectedFromDate
          //   ? selectedFromDate?.format("YYYY-MM-DD")
          //   : null,
          // to_date: selectedToDate ? selectedToDate?.format("YYYY-MM-DD") : null,
          // "page": 0,
          // "limit": 40,
          // "paginate": true
        },
      });

      setRowsLiablities(response?.data?.data);

      setTotalCount(response?.data?.count);
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });

      if (error?.response?.status == 403) {
        console.log("error", error);
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsGettingRowsLiablities(false);
    }
  }

  // site tbl

  const [rowsSite, setRowsSite] = useState([]);
  const [isGettingRowsSite, setIsGettingRowsSite] = useState(false);

  async function getRowsSite() {
    try {
      setIsGettingRowsSite(true);
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/getvendordeliveryitem`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: {
          "user_code": null,
          "site_code": null,
          from_date: selectedFromDate
            ? selectedFromDate?.format("YYYY-MM-DD")
            : null,
          to_date: selectedToDate
            ? selectedToDate?.format("YYYY-MM-DD")
            : null,
          // "paginate": false,
          "discrepancy": true
        },
      });

      setRowsSite(response?.data?.data || []);
      // if (response?.data?.data?.length) {
      //   setSelectedRow({
      //     value: response?.data?.data[0]?.id,
      //     label:
      //       response?.data?.data[0]?.id +
      //       ")-" +
      //       response?.data?.data[0]?.vendor?.code +
      //       "-" +
      //       response?.data?.data[0]?.quote_date,
      //     quote: response?.data?.data[0],
      //   });
      // } else {
      //   setSelectedRow(null);
      // }
      // setTotalCount(response?.data?.count);
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsGettingRowsSite(false);
    }
  }

  // useEffect(() => {
  //   getRows();
  //   getRowsMismatch();
  //   getRowsLiablities();
  //   getRowsSite();
  // }, [selectedToDate]);
  useEffect(() => {
    if (checkSelected() && selectedToDate) {
      if (checkBoxesState.transaction) {
        getRowsMismatch();
      }
      if (checkBoxesState.liabilities) {
        getRowsLiablities();
      }
      if (checkBoxesState.home) {
        getRows();
      }
      if (checkBoxesState.site) {
        getRowsSite();
      }
    } else {
      getRows();
      getRowsMismatch();
      getRowsLiablities();
      getRowsSite();
    }
  }, [selectedToDate, checkBoxesState]);

  return <>
    <div className="inner-box pt-3">
      <div className="">
        <h1 className="heading p-0 m-0 text-center">Dashboard</h1>
        <div className="d-flex pb-3 ">
          <div className="w-45 ms-auto">
            {/* <input
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              type="date"
              className="input-box gray"
            /> */}
            <RangePicker
              className="input-box gray"
              value={[selectedFromDate, selectedToDate]}
              format={"DD MMM YYYY"}
              onChange={(dates) => {
                setSelectedFromDate(dates?.length ? dates[0] : "");
                setSelectedToDate(dates?.length ? dates[1] : "");
              }}
            />
          </div>
        </div>
      </div>

      <div className="mb-20">
        <div className="row row-cols-1 row-cols-md-2 g-4">
          <div className="col">
            <div className="inner-box px-3 py-2">
              <h5 className="headingTable">
                <div class="form-check position-absolute left-0 top-0">
                  <input class="form-check-input" type="checkbox" checked={checkBoxesState?.transaction} id="transaction" onChange={handleChange} />
                </div>
                Daily Trasaction Mismatched
                <button className="TblBtn" type="button" onClick={() => navigate('/cash_track/mismatched')}>
                  <svg className="icon">
                    <use href="#icon_roundArrow"></use>
                  </svg>
                </button>
              </h5>
              <div ref={componentRef} className="tbl-scroller">
                <table className="table-wrapper">
                  <thead>
                    <tr>
                      <th className="srtd">Sr.No.</th>
                      {columns2.map((column) => (
                        <th key={column.code}>{column.name}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="tbody-gap">
                    <tr>
                      <td colSpan="13"></td>
                    </tr>
                  </tbody>

                  <tbody>
                    {rowsMismatch.map((row, index) => (
                      <tr key={row?.id}>
                        <td>{index + 1}</td>
                        {/* <td>{parseInt(page) * limit + index + 1}</td> */}
                        {columns2.map((column) => (
                          <td key={column.code}>
                            {column.format
                              ? column.format(row[column.code])
                              : row[column.code]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
                {isGettingRowsMismatch && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Loader color={"#aa741f"} />
                  </div>
                )}
                {!rowsMismatch.length && !isGettingRowsMismatch && (
                  <div className="d-flex jc-center">
                    <span>No data found</span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col">
            <div className="inner-box px-3 py-2">
              <h5 className="headingTable">
                <div class="form-check position-absolute left-0 top-0">
                  <input class="form-check-input" type="checkbox" checked={checkBoxesState?.liabilities} id="liabilities" onChange={handleChange} />
                </div>
                Liablities
                <button className="TblBtn" type="button" onClick={() => navigate('/bills/all_bill')}>
                  <svg className="icon">
                    <use href="#icon_roundArrow"></use>
                  </svg>
                </button>
              </h5>
              <div className="tbl-scroller">
                {!isGettingRowsLiablities && rowsLiablities?.length ? (
                  <table className="table-wrapper">
                    <thead>
                      <tr>
                        <th className="srtd">Sr. No.</th>
                        <th>Firm/Person</th>
                        <th>Code</th>
                        <th>Operator</th>
                        <th>Consumer code</th>
                        {/* <th>Past Bill Amount</th> */}
                        <th>New Bill Amount</th>
                        <th>Due Date</th>
                        <th>Payment Status</th>
                      </tr>
                    </thead>
                    <tbody className="tbody-gap">
                      <tr>
                        <td colSpan="13"></td>
                      </tr>
                    </tbody>

                    {!isGettingRowsLiablities && (
                      <tbody>
                        {rowsLiablities.map((row, index) => (
                          <tr key={row?.id}>
                            {/* <td>{parseInt(page) * limit + index + 1}</td> */}
                            <td>{index + 1}</td>
                            <td>{row?.sitedata?.code}</td>
                            <td>{row?.site_code}</td>
                            <td>{row?.operator?.operator}</td>
                            <td
                            // style={{
                            //   cursor: "pointer",
                            // }}
                            >
                              <a
                                href=""
                                onClick={(e) => {
                                  e.preventDefault();
                                  setDisplayModal(row);
                                }}
                              >
                                {row?.account}
                              </a>
                            </td>
                            {/* <td>{row?.last_bill_amount}</td> */}
                            <td>{row?.last_bill_amount}</td>
                            <td>
                              {row?.last_bill_due_date
                                ? moment(row?.last_bill_due_date).format("DD MMM YYYY")
                                : ""}
                            </td>
                            <td>
                              {/* 0=Not Paid, 1=partial, 2=Paid, 3=Failed */}
                              {/* {row?.payment_status == 1 ? (
                                <span className="badge rounded-2 text-bg-paid">
                                  Paid
                                </span>
                              ) : (
                                <span className="badge rounded-2 text-bg-unpaid">
                                  Unpaid
                                </span>
                              )} */}
                              {row?.payment_status == 0 && (
                                <span className="badge rounded-2 text-bg-unpaid">
                                  Unpaid
                                </span>
                              )}
                              {row?.payment_status == 1 && (
                                <span className="badge rounded-2 text-bg-partial">
                                  Partial
                                </span>
                              )}
                              {row?.payment_status == 2 && (
                                <span className="badge rounded-2 text-bg-paid">
                                  Paid
                                </span>
                              )}
                              {row?.payment_status == 3 && (
                                <span className="badge rounded-2 text-bg-failed">
                                  Failed
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                ) : (
                  <div className="d-flex jc-center">
                    <span>No data found</span>
                  </div>
                )}
                {isGettingRowsLiablities && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Loader color={"#aa741f"} />
                  </div>
                )}
                {!rowsLiablities.length && !isGettingRowsLiablities && (
                  <div className="d-flex jc-center">
                    <span>No data found</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <h1 className="heading text-center">Mismatch</h1>
      <div className="row row-cols-1 row-cols-md-2 g-4">
        <div className="col">
          <div className="inner-box px-3 py-2">
            <h5 className="headingTable">
              <div class="form-check position-absolute left-0 top-0">
                <input class="form-check-input" type="checkbox" checked={checkBoxesState?.home} id="home" onChange={handleChange} />
              </div>
              Petty Expenses
              <button className="TblBtn" type="button" onClick={() => navigate('/discrepancy/purchase')}>
                <svg className="icon">
                  <use href="#icon_roundArrow"></use>
                </svg>
              </button>
            </h5>
            <div className="tbl-scroller">
              {!isGettingRows && rows?.length ? (
                <table className="table-wrapper">
                  <thead>
                    <tr>
                      <th className="srtd">Sr.No.</th>
                      {columns.map((column) => (
                        <th key={column.code}>{column.name}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="tbody-gap">
                    <tr>
                      <td colSpan="10"></td>
                    </tr>
                  </tbody>

                  <tbody>
                    {rows.map((row, index) => (
                      <>
                        <tr key={row?.id}>
                          <td>{index + 1}</td>
                          {columns.map((column) => (
                            <td key={column.code}>
                              {column.format
                                ? column.format(row[column.code])
                                : row[column.code]}
                            </td>
                          ))}
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="d-flex jc-center">
                  <span>No data found</span>
                </div>
              )}
              {isGettingRows && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loader color={"#aa741f"} />
                </div>
              )}
              {!rows.length && !isGettingRows && (
                <div className="d-flex jc-center">
                  <span>No data found</span>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="col">
          <div className="inner-box px-3 py-2">
            <h5 className="headingTable">
              <div class="form-check position-absolute left-0 top-0">
                <input class="form-check-input" type="checkbox" checked={checkBoxesState?.site} id="site" onChange={handleChange} />
              </div>
              Site
              <button className="TblBtn" type="button" onClick={() => navigate('/discrepancy/site')}>
                <svg className="icon">
                  <use href="#icon_roundArrow"></use>
                </svg>
              </button>
            </h5>
            <div className="tbl-scroller">
              {!isGettingRowsSite && rowsSite?.length ? (
                <table className="table-wrapper">
                  <thead>
                    <tr>
                      <th className="srtd">Sr.No.</th>
                      {columnsSite.map((column) => (
                        <th key={column.code}>{column.name}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="tbody-gap">
                    <tr>
                      <td colSpan="10"></td>
                    </tr>
                  </tbody>

                  <tbody>
                    {rowsSite?.map((row, index) => (
                      <>
                        <tr key={row?.id}>
                          <td>{index + 1}</td>
                          {columnsSite.map((column) => (
                            <td key={column.code}>
                              {column.format
                                ? column.format(row[column.code], row["qty_price"])
                                : row[column.code]}
                            </td>
                          ))}
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="d-flex jc-center">
                  <span>No data found</span>
                </div>
              )}
              {isGettingRowsSite && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loader color={"#aa741f"} />
                </div>
              )}
              {!rowsSite.length && !isGettingRowsSite && (
                <div className="d-flex jc-center">
                  <span>No data found</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>

    <InvoiceViewModal
      showInvoice={showInvoice}
      handleClose={() => setShowInvoice(false)}
    />

  </>;
}

export default Dashboard;
